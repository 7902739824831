import React, { useState } from 'react'
import Img from 'gatsby-image'

function GalleryImage({ image }) {

	const [hover, setHover] = useState(false)
	var overlayClass, captionClass = ``

	if (hover) {
		overlayClass = `overlay`
		captionClass = `has-text-light is-uppercase is-size-2 gallery-caption`
	} else {
		overlayClass = `hidden`
	}
		
	return (
        
		<div className={`gallery-image-container`} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
			<Img fluid={image.fluid} title={image.title} alt={image.title} />
			{image.title &&
				<div className={overlayClass}>
					<div className={captionClass}>
						{image.title}
					</div>
				
				</div>
			}
		</div>
	)
}

export default GalleryImage