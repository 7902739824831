import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/common/layout'
import SEO from '../components/common/seo'
import GalleryImage from '../components/gallery/galleryImage'

const GalleryPage = ({ data }) => {
	const galleryImgs = data.gallery.media
	const pageDetails = data.galleryDeets.edges[0].node

	return (
		<Layout pageTitle={pageDetails.title}>
			<SEO
				title={pageDetails.title}
				description={pageDetails.description}
				img={pageDetails.shareImage.file.url}
			/>
            
			<div className="gallery-container">
				{galleryImgs.map((img) =>
					<GalleryImage image={img} key={img.title} />
				)}
			</div>
            
		</Layout>
	)
}

export default GalleryPage

export const galleryQuery = graphql`
    query GalleryQuery {
        gallery:contentfulGallery(title: {eq: "Gallery"}) {
            title
            description {
                content {
                    content {
                        value
                    }
                }
            }
            media {
				description
                title
                file {
                    url
                }
                fluid(maxWidth: 1920) {
					...GatsbyContentfulFluid
                }
            }
        }
        galleryDeets:allContentfulPageDetails(filter: {title: {eq: "Gallery"}}) {
			edges {
				node {
					title
					description
					shareImage {
						file {
							url
						}
					}
				}
			}
		}
    }
`
